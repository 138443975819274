import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { useSpring, animated } from 'react-spring';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from './Shared/Container';

export default function About() {
  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(30px)' },
    opacity: 1,
    transform: 'translateY(0px)'
  });
  const AnimatedFlex = animated(Flex);
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "0.jpeg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Container
      maxWidth="950px"
      style={{ transform: 'translate3d(0,-100px,0)' }}
    >
      <AnimatedFlex
        style={props}
        flexDirection="column"
        alignItems="center"
        pt={5}
        px={[2, 4]}
      >
        <Flex
          width={[1, 3 / 4]}
          p={4}
          backgroundColor="white"
          css={{
            boxShadow: '2px 2px 10px -5px rgba(0, 0, 0, 0.3)',
            border: '1px solid rgba(0,0,0,0.05)',
            borderRadius: 20
          }}
          alignItems="center"
          flexDirection="column"
        >
          <Text fontSize={4} fontWeight={500}>
            Hi! I'm Shashank{' '}
            <span role="img" aria-label="wave-emoji">
              👋
            </span>
          </Text>

          <Flex alignItems="center" flexDirection={['column', 'row']}>
            <Box mx={3} mt={4}>
              <Img fixed={data.image.childImageSharp.fixed} />
            </Box>
            <Text fontSize={2} fontWeight={400} mx={3} mt={4} width={[1, 0.5]}>
              I'm a cybersecurity analysist and a part-time bug bounty hunter.
              Currently, I am a security analyst at{' '}
              <a href="https://hackerone.com">HackerOne</a> and{' '}
              <a href=" https://dotqii.com">DotQii</a>. I am also a Team Lead at{' '}
              <a href="https://app.cobalt.io/pentesters">Cobalt</a>, a
              crowdsourced security-as-a-service company
            </Text>
          </Flex>
          <Text fontSize={2} fontWeight={400} my={4} mx={3}>
            I have helped secure several companies like{' '}
            <a href="https://www.facebook.com/whitehat/thanks/">Facebook</a>,{' '}
            <a href="https://www.google.com/about/appsecurity/hall-of-fame/archive/">
              Google
            </a>
            , Apple{' '}
            <a href="https://support.apple.com/en-in/HT207923">
              CVE-2017-7063 , CVE-2017-7062
            </a>
            ,{' '}
            <a href="https://support.apple.com/en-in/HT207617">CVE-2017-2458</a>
            ,{' '}
            <a href="https://www.microsoft.com/en-us/msrc/researcher-acknowledgments-online-services-archive">
              Microsoft
            </a>
            , and 40+ websites in the past and can be found in their Hall of
            Fame.
            <br />
            <br />
            My story has been covered by many techs & business media
            publications like{' '}
            <a href="https://www.livemint.com/Leisure/XCHxVbc3GoAuaqEGTlllbL/Get-a-Glimpse-Cybersecurity-experts.html">
              LiveMint
            </a>
            ,{' '}
            <a href="https://www.theverge.com/2015/3/4/8140919/get-paid-for-hacking-bug-bounty-hackerone-synack">
              TheVerge
            </a>
            ,{' '}
            <a href="https://tech.economictimes.indiatimes.com/news/internet/post-demonetisation-ethical-hackers-are-in-high-demand-to-fix-payment-app-issues/56325429">
              IndiaTimes
            </a>
            , etc. A book was also written on my journey by the name
            <a href="https://www.goodreads.com/en/book/show/35152406-career-rules">
              {' '}
              'Career Rules: How to Choose Right and Get the Life You Want'
            </a>
            <br />
            <br />
            My other interests include cryptocurrency trading, mentoring
            aspiring security professionals, and reading. When not working on
            securing the internet, I can be found playing Dota, traveling places
            or reading novels.
          </Text>
        </Flex>
      </AnimatedFlex>
    </Container>
  );
}
