import React from 'react';

import Layout from '../components/layout';
import { Container } from '../components/layoutComponents';
import About from '../components/About';
import SEO from '../components/seo';

const AboutPage = () => (
  <Layout>
    <Container>
      <SEO
        title="About"
        keywords={[
          `about`,
          `shahsank`,
          `cyberboyindia`,
          'hacking',
          'ethical',
          'teaching'
        ]}
      />
      <About />
    </Container>
  </Layout>
);

export default AboutPage;
